import styled from "styled-components";

const ButtonStyles = styled.a`
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;

  display: inline-block;
  text-decoration: none;
  background-color: var(--secondary);
  border-radius: var(--borderRadius);
  color: var(--primary) !important;
  padding: 1rem;
  margin: 0.5rem 0;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    background-color: var(--pop);
    color: var(--darkgrey) !important;
    transition: all var(--transition-speed);
  }
`;

const DisabledButtonStyles = styled.button`
  background-color: var(--primary);
  border: 1px solid var(--disabled);
  border-radius: var(--borderRadius);
  color: var(--disabled);
  padding: 1rem;
  margin: 0.5rem 0;
  text-decoration: none;
  font-weight: bold;
`;

export default function Button({ href, buttonText, disabled }) {
  if(disabled) {
    return (
      <DisabledButtonStyles disabled>
        {buttonText}
      </DisabledButtonStyles>
    )
  }
  return (
    <ButtonStyles
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="button-link"
    >
      {buttonText}
    </ButtonStyles>
  )
}
