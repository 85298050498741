import { graphql } from "gatsby";
import { useContext } from "react";
import Footer from "../components/Footer";
import Headline from "../components/Headline";
import LayoutStyles from "../components/layout";
import MainContent from "../components/mainContent";
import Navbar from "../components/Navbar";
import ProjectList from "../components/ProjectList";
import SEO from "../components/SEO";
import ThemeContext from "../contexts/ThemeContext";
import GlobalStyles from "../styles/GlobalStyles";

export default function ProjectsPage({ data }) {
  const { theme } = useContext(ThemeContext);
  const projects = data?.allMarkdownRemark?.edges;

  return (
    <LayoutStyles>
      <GlobalStyles theme={theme} />
      <SEO
        title="Projects | A collection of Software Development projects"
        titleTemplate={data?.site?.siteMetadata?.title}
        description={data?.site?.siteMetadata?.description}
      />
      <Navbar />
      <Headline
        title="Projects"
        subtitle="A collection of work and personal projects"
      />
      <MainContent>
        <ProjectList projects={projects} />
      </MainContent>
      <Footer />
    </LayoutStyles>
  );
}

export const pageQuery = graphql`
  query ProjectsPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { isProject: { eq: true } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            description
            title
            github
            productionUrl
            date
          }
        }
      }
    }
  }
`;
