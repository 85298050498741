import styled from "styled-components";
import ProjectCard from "./ProjectCard";

const ProjectListStyles = styled.section`
  width: 100%;
  display: grid;
  gap: 2rem 2rem;
  grid-template-columns: repeat(auto-fill, minmax(var(--column-width), 1fr));
  margin: 3rem 0;
`;

export default function ProjectList({projects = []}) {
  return (
    <ProjectListStyles>
      {projects.map(({ node }) => <ProjectCard key={node.id} project={node} />)}
    </ProjectListStyles>
  )
}
