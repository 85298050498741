import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { shadow } from "../styles/GlobalStyles";
import Button from "./Button";

const ProjectStyles = styled.article`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  color: var(--secondary);
  padding: 1rem;
  border-radius: 5px;
  background-color: var(--primary);

  ${shadow}

  .project-github-image {
    background-color: var(--white);
    img {
      filter: opacity(0.8);
    }
  }

  .project-github-image:hover {
    background-color: var(--pop);
    transition: all var(--transition-speed);
  }

  .no-github-link {
    background-color: var(--primary) !important;
    img {
      filter: opacity(0.5);
    }
    &:hover {
      background-color: var(--primary) !important;
    }
  }

  .project-details {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    flex: 1;
    margin-left: 1rem;
    height: 100%;
    a {
      text-align: center;
    }
  }

  .project-details h1 {
    margin: 0;
  }

  .button-group {
    display: flex;
    justify-content: space-between;
  }
`;

const renderLinkToExample = (productionUrl) => {
  const buttonText = productionUrl ? "Live example" : "Not available";
  return <Button buttonText={buttonText} href={productionUrl} disabled={!productionUrl} className="link-to-example" />;
}

const renderGithubIconLink = (githubHref) => {
  const iconClass = githubHref
    ? "project-github-image"
    : "project-github-image no-github-link";

  const icon = (
    <StaticImage
      src="../images/github.webp"
      alt="Github"
      placeholder="blurred"
      layout="fixed"
      width={60}
      height={60}
      style={{ borderRadius: "50%" }}
      className={iconClass}
    />
  );

  const iconLink = githubHref ? (
    <a
      href={githubHref}
      target="_blank"
      rel="noopener noreferrer"
    >
      {icon}
    </a>
  ) : (
    icon
  );

  return iconLink;
}

export default function ProjectCard({ project }) {
  const { frontmatter } = project;

  return (
    <ProjectStyles>
      <div className="project-details">
        <div>
          <h1>{frontmatter.title}</h1>
          <p>{frontmatter.description}</p>
        </div>
        <div className="button-group">
          {renderGithubIconLink(frontmatter.github)}
          {renderLinkToExample(frontmatter.productionUrl)}
        </div>
      </div>
    </ProjectStyles>
  );
}
